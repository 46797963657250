<template>
  <div class="users">
    <ConfigurationHeaderContainer title="Users for Project X"></ConfigurationHeaderContainer>
    <div class="form-entry-container">
      <div class="form-entry-label">Project Users</div>
      <div class="form-entry-input">
        <div class="forms">
        <button>+ Add User...</button>
          <kendo-grid
            :id="'userGrid'"
            :ref="'userGrid'"
            :data-source="dataSource"
            :selectable="selectable"
            style="width: calc(100% - 320px);">
            <kendo-grid-column field="Users" title="User ID" width="40px" class="grid-header-light"></kendo-grid-column>
            <kendo-grid-column field="Users" title="Name" width="40px"></kendo-grid-column>
            <kendo-grid-column field="Users" title="Email" width="40px"></kendo-grid-column>
            <kendo-grid-column field="Users" title="Group Permission" width="40px" template="SLR Admin <button>Edit Project Permissions</button>"></kendo-grid-column>
            <kendo-grid-column title="" width="40px" template="<button>Remove</button>"></kendo-grid-column>
            <!-- <kendo-grid-column field="Users" title="Selected Users" width="40px"></kendo-grid-column> -->
            <!-- <kendo-grid-column field="Role" title="Roles" width="40px"></kendo-grid-column> -->
            <!-- <kendo-grid-column field="AssignedForms" title="Assigned Forms" width="40px" :template="assignedFormsTemplate"></kendo-grid-column>
            <kendo-grid-column field="AllowedStages" title="Allowed Stages" width="40px" :template="allowedStagesTemplate"></kendo-grid-column> -->
            <!-- <kendo-grid-column field="AllowedStages" title="Assign permitted Citation Id range for this User" width="80px" template="<b>Start:</b>&nbsp;<input type='text' size='5'/>&nbsp;<b>Stop:</b>&nbsp;<input type='text' size='5'/>"></kendo-grid-column> -->
          </kendo-grid>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ConfigurationHeaderContainer from '@/containers/ConfigurationHeaderContainer'
import AssignedFormsTemplate from '@/views/secure/configuration/templates/AssignedForms'
import AllowedStagesTemplate from '@/views/secure/configuration/templates/AllowedStages'
import UserDataSource from '@/assets/data/User.json'

export default {
  name: 'users',
  components: {
    ConfigurationHeaderContainer
  },
  methods: {
    assignedFormsTemplate: function (e) {
      return {
        template: AssignedFormsTemplate,
        templateArgs: e
      }
    },
    allowedStagesTemplate: function (e) {
      return {
        template: AllowedStagesTemplate,
        templateArgs: e
      }
    }
  },
  data () {
    return {
      selectable: true,
      userDataSource: UserDataSource,
      dataSource: {
        data: [
          {
            Users: 'Kim Smith',
            Role: 'Data Manager',
            AssignedForms: 'Form1, Form 2',
            AllowedStages: 'Abstract Screening, Added Materials'
          },
          {
            Users: 'Roger Cross',
            Role: 'Data Entry Specialist',
            AssignedForms: 'Form 3',
            AllowedStages: 'Added Materials'
          }
        ]
      }
    }
  }
}
</script>
